import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./common";

export const siteApi = createApi({
  reducerPath: "siteApi",
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getSiteByCode: builder.query<any, string>({
      query: (code) => `/api/sites/site/${code}`,
    }),
    getSiteByDomain: builder.query<any, string>({
      query: (domain) => `/api/sites/domain/${domain}`,
    }),
  }),
});
export const { useGetSiteByDomainQuery, useGetSiteByCodeQuery } = siteApi;
