import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getConfig from "next/config";

import { TokenType } from "@/core/store/redux/auth/types";
import { ReduxState } from "@/core/store/types";

const { publicRuntimeConfig } = getConfig();

export const baseQuery = () => {
  return fetchBaseQuery({
    baseUrl: publicRuntimeConfig.API_BASEURL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as ReduxState;
      if (state.auth.token?.type == TokenType.USER) {
        headers.set("X-Authorization", `Bearer ${state.auth.token.token}`);
      } else if (state.auth.token?.type == TokenType.GUEST) {
        headers.set("X-Guest-Authorization", `Guest ${state.auth.token.token}`);
      }
      if (state.site.code) {
        headers.set("X-Site-Code", state.site.code);
      }
      if (state.site.appDomain) {
        headers.set("X-Site-Domain", state.site.appDomain);
      }
      headers.set("x-client-build-id", publicRuntimeConfig.BUILD_ID);
      headers.set("x-client-build-date", publicRuntimeConfig.BUILD_DATE);
      headers.set("Accept-Language", "ro-RO");
      return headers;
    },
  });
};
