import { accountApi } from "./account";
import { agreementsApi } from "./agreements";
import { authApi } from "./auth";
import { bookingsApi, publicBookingsApi } from "./bookings";
import { chatApi } from "./chat";
import { helpApi } from "./help";
import { pagesApi } from "./pages";
import { providerApi } from "./provider";
import { providersApi } from "./providers";
import { salesApi } from "./sales";
import { siteApi } from "./site";

export const apis = [
  helpApi,
  accountApi,
  salesApi,
  agreementsApi,
  providerApi,
  providersApi,
  bookingsApi,
  publicBookingsApi,
  authApi,
  chatApi,
  siteApi,
  pagesApi,
];

export const apiMiddlewares = [
  helpApi.middleware,
  accountApi.middleware,
  salesApi.middleware,
  agreementsApi.middleware,
  providerApi.middleware,
  providersApi.middleware,
  bookingsApi.middleware,
  publicBookingsApi.middleware,
  authApi.middleware,
  chatApi.middleware,
  siteApi.middleware,
  pagesApi.middleware,
];

export const apiReducers = {
  [helpApi.reducerPath]: helpApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [salesApi.reducerPath]: salesApi.reducer,
  [agreementsApi.reducerPath]: agreementsApi.reducer,
  [providerApi.reducerPath]: providerApi.reducer,
  [providersApi.reducerPath]: providersApi.reducer,
  [bookingsApi.reducerPath]: bookingsApi.reducer,
  [publicBookingsApi.reducerPath]: publicBookingsApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [siteApi.reducerPath]: siteApi.reducer,
  [pagesApi.reducerPath]: pagesApi.reducer,
};
