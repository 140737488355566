import { ReduxState } from "@/store/types";

import { AuthStatus, Guest, Token, User, UserSiteDomain } from "./types";

const status = (state: ReduxState): AuthStatus => {
  return state.auth.status;
};

const guest = (state: ReduxState): Guest | undefined => {
  return state.auth.guest;
};

const user = (state: ReduxState): User | undefined => {
  return state.auth.user;
};

const userPreference = (state: ReduxState) => {
  return state.auth.userPreference;
};

const userSecurity = (state: ReduxState) => {
  return state.auth.userSecurity;
};

const siteDomain = (state: ReduxState): UserSiteDomain => {
  return state.auth.siteDomain;
};

const isUser = (state: ReduxState): boolean => {
  return state.auth.status == AuthStatus.READY && state.auth.user != undefined;
};

const token = (state: ReduxState): Token | undefined => {
  return state.auth.token;
};

export const authSelectors = {
  guest,
  user,
  userPreference,
  userSecurity,
  siteDomain,
  isUser,
  token,
  status,
};
