import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetCustomerInfoQuery } from "@/core/api/services/account";
import { useGetAgreementsUnsignedCountQuery } from "@/core/api/services/agreements";
import { useGetSlotsQuery } from "@/core/api/services/bookings";
import { actionItemsActions } from "@/core/store/redux/actionItems/actions";
import { UserStatusType } from "@/core/store/redux/auth/types";
import { authSelectors } from "@/core/store/redux/auth/selectors";
import { providerSelectors } from "@/core/store/redux/provider/selectors";

const AppRTKToReduxUserInfo: React.FC = () => {
  const dispatch = useDispatch();
  const customerInfo = useGetCustomerInfoQuery(undefined, {
    pollingInterval: 1000 * 60 * 5, // 5 minutes
  });
  useEffect(() => {
    if (!customerInfo.isSuccess) return;
    dispatch(actionItemsActions.setHasIncompleteProfile(customerInfo.data?.is_complete === false));
  }, [customerInfo.data?.is_complete]);

  return null;
};

const AppRTKToReduxWithActiveProvider: React.FC = () => {
  const dispatch = useDispatch();
  const unsignedAgreements = useGetAgreementsUnsignedCountQuery(undefined, {
    pollingInterval: 1000 * 60 * 5, // 5 minutes
  });
  const topSlots = useGetSlotsQuery(
    {
      locked: true,
      limit: 100,
      in_progress_status: "in_progress,in_future",
      ordering: "-locked_reason,start_at",
    },
    {
      pollingInterval: 1000 * 60 * 5, // 5 minutes
    },
  );

  useEffect(() => {
    if (!unsignedAgreements.isSuccess) return;
    dispatch(actionItemsActions.setHasPendingConsent(unsignedAgreements?.data?.count > 0));
  }, [unsignedAgreements?.data?.count]);

  useEffect(() => {
    if (!topSlots.isSuccess) return;
    const pendingAcceptance = topSlots?.data?.results?.find((slot: any) => slot.locked_reason === "pending_acceptance");
    dispatch(actionItemsActions.setHasBookingInvite(!!pendingAcceptance));

    const inProgress = topSlots?.data?.results?.find(
      (slot: any) => slot.booking && slot.in_progress_status === "in_progress",
    );
    dispatch(actionItemsActions.setActiveBooking(inProgress || undefined));
  }, [topSlots?.data?.results]);

  return null;
};

export const AppRTKToRedux = () => {
  const profile = useSelector(providerSelectors.profile);
  const user = useSelector(authSelectors.user);
  const children = [];
  if (user?.status == UserStatusType.ACTIVE) {
    children.push(<AppRTKToReduxUserInfo key="active_user" />);
    if (!profile) children.push(<AppRTKToReduxWithActiveProvider key="with_provider" />);
  }
  return <>{children}</>;
};
