import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Channel, StreamChat } from "stream-chat";
import { authSelectors } from "@/core/store/redux/auth/selectors";
import { UserStatusType } from "@/core/store/redux/auth/types";
import { chatActions } from "@/core/store/redux/chat/actions";
import { chatSelectors } from "@/core/store/redux/chat/selectors";
import { ChatStatus } from "@/core/store/redux/chat/types";
import { providerSelectors } from "@/core/store/redux/provider/selectors";

type ChatContextType = {
  isConnected: boolean;
  client?: StreamChat;
  channel?: Channel;
  chatStatus: ChatStatus;
};

export const AppChatChatContext = React.createContext<ChatContextType | null>(null);

const AppChatContext: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = React.useState(false);
  const [channel, setChannel] = React.useState<Channel>();
  const [client, setClient] = React.useState<StreamChat>();
  const profile = useSelector(providerSelectors.profile);
  const config = useSelector(chatSelectors.config);
  const chatStatus = useSelector(chatSelectors.status);

  useEffect(() => {
    dispatch(chatActions.initialize());
  }, [profile]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClientMessage = useCallback((event: any) => {
    if (event.total_unread_count !== undefined) {
      dispatch(chatActions.setUnreadChannelMessagesCount(event.total_unread_count));
    }
  }, []);

  const connect = useCallback(
    async (c: StreamChat) => {
      const connection = await c.connectUser(
        {
          id: config.user_id,
          total_unread_count: true,
          unread_channels: true,
        },
        config.token,
      );
      if (connection?.me?.total_unread_count) {
        dispatch(chatActions.setUnreadChannelMessagesCount(connection.me.total_unread_count));
      }
      const channel = c.channel("one2one", config.channel_id);
      setChannel(channel);
      setIsConnected(true);
    },
    [config],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const disconnect = useCallback(
    async (c: StreamChat) => {
      setChannel(undefined);
      await c.disconnectUser(); // !TODO - check if closeConnection is needed
      setIsConnected(false);
    },
    [config], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!config) return;
    const c = new StreamChat(config.api_key, { timeout: 6000 });
    connect(c).catch(console.error);
    setClient(c);
    return () => {
      disconnect(c).catch(console.error);
      setClient(undefined);
    };
  }, [config]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //better use notification.message_new || notification.mark_read ?
    const listener = client?.on("all", onClientMessage);
    return listener?.unsubscribe;
  }, [client]);

  if (!client || !channel) return <>{children}</>;

  const value = { client, channel, chatStatus, isConnected };
  return <AppChatChatContext.Provider value={value}>{children}</AppChatChatContext.Provider>;
};

export const AppChatContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const profile = useSelector(providerSelectors.profile);
  const user = useSelector(authSelectors.user);
  if (user?.status != UserStatusType.ACTIVE) return <>{children}</>;
  if (!profile) return <>{children}</>;

  return <AppChatContext>{children}</AppChatContext>;
};
