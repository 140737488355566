import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "./common";
import { addQueryDictToUrl, offsetLimitMergeResults, offsetLimitSerializeQueryArgs } from "../utils";

export const providersApi = createApi({
  reducerPath: "providersApi",
  baseQuery: baseQuery(),
  tagTypes: ["Providers"],
  endpoints: (builder) => ({
    providersSearch: builder.query<ListResponse<any>, Record<string, any> | void>({
      query: (queryParams) => addQueryDictToUrl(`/api/providers-profiles/public/search`, queryParams),
      serializeQueryArgs: offsetLimitSerializeQueryArgs,
      merge: offsetLimitMergeResults,
      providesTags: ["Providers"],
    }),
  }),
});
export const { useProvidersSearchQuery, useLazyProvidersSearchQuery } = providersApi;
